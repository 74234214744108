import React, { useState } from "react";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "../styles/Haz.css";
import ClickableImage from "./ClickableImage";
import vandor2 from "../assets/vandor2.jpg";
import vandor22 from "../assets/furdo1.jpg";
import vandor222 from "../assets/furdo2.jpg";
//import vandor11 from "../assets/vandor11.jpg";//

const slides = [
  { src: vandor2, alt: "Vandor2 Ház", className: "img" },
  { src: vandor22, alt: "Vandor2 Ház", className: "img" },
  { src: vandor222, alt: "Vandor2 Ház", className: "img" },
 // { src: vandor11, alt: "Vandor2 Ház", className: "img" }//
];

const Haz2 = () => {
  const [index, setIndex] = useState(-1);

  return (
   <div className="keret">
   <h2>Kökörcsin</h2>
   <p33>Ez a ház Kecskemét vonzáskörzetében található.</p33>
    <>
      <div className="img">
        {slides.map((slide, index) => (
          <ClickableImage
            key={index}
            src={slide.src}
            alt={slide.alt}
            onClick={() => setIndex(index)}
            className="custom-lightbox"
          />
        ))}
      </div>

      <PhotoAlbum
        className="img"
        photos={slides}
        targetRowHeight={150}
        onClick={({ index: current }) => setIndex(current)}
      />

      <Lightbox
        index={index}
        slides={slides}
        open={index >= 0}
        close={() => setIndex(-1)}
        className="custom-lightbox"
        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
      />
    </>
    </div>
  );
};

export default Haz2;
