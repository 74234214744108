import { useRef } from "react";
import "../styles/Kapcsolat.css";
import emailjs from '@emailjs/browser';
import hatter from "../assets/telep2.jpg";
import { FiPhoneCall } from 'react-icons/fi';
import { TbBuildingSkyscraper } from 'react-icons/tb';
import { GrMail } from 'react-icons/gr';
import { HiOutlineOfficeBuilding } from 'react-icons/hi';

function Kapcsolat () {

  const form = useRef(null);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_c85k00p',
     'template_y48lbqd',
      form.current,
       'mmazdBD3MpOac1Spo')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
  };
  return(
    <section className="all">
      <div class="hatter">
        <img src={hatter}/>
      </div>
      <div className="container">
         <h2 className="text-center">Keress minket bizalommal!</h2>
      </div>
      <div className="bal">
      <div className="telephely">
      <p1><TbBuildingSkyscraper /> 6000 Kecskemét, Magnólia utca 30.</p1>
      </div>
      <div className="telephely">
      <p1><HiOutlineOfficeBuilding /> 6000 Kecskemét, Kőrösi-hegy 36.</p1>
      </div>
      <div className="telefon">
      <p1><FiPhoneCall /> +36205339466 </p1>
      </div>
      <div className="email">
      <p1><GrMail /> info@redhomebau.hu</p1>
      </div>
  </div>
  
        <div className="jobb">
         <form ref={form} 
         onSubmit={sendEmail}
         className="form">
          <input type="text"
          placeholder='Teljes név'
          name='user_name' required />
          <input type="email"
          placeholder='Email'
          name='user_email' required />
          <input type="text"
          placeholder='Tárgy'
          name='subject' required />
          <textarea name='uzenet'
          placeholder='Ide írhatod az üzenetedet! ;) '
          cols="30" rows="5" />
          <button type='submit' className="btn1">Üzenet küldése</button>
         </form>
      </div>
    </section>
  )
}

export default Kapcsolat
