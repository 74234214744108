import React from "react";
import alapr6 from "../assets/alapr6.jpg";
import "../styles/Alapr.css";

const Alapr6 = () => {
  return (
    <div className="alapr6">
            <img src={alapr6} alt="alapr6" />
          </div>
  );
};

export default Alapr6;
