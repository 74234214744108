import React from "react";
import alapr3 from "../assets/alapr3.jpg";
import "../styles/Alapr.css";

const Alapr3 = () => {
  return (
    <div className="alapr3">
            <img src={alapr3} alt="alapr3" />
          </div>
  );
};

export default Alapr3;
