import React from "react";
import alapr5 from "../assets/alapr5.jpg";
import "../styles/Alapr.css";

const Alapr5 = () => {
  return (
    <div className="alapr5">
            <img src={alapr5} alt="alapr5" />
          </div>
  );
};

export default Alapr5;
