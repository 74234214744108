import React from "react";
import alapr4 from "../assets/alapr4.jpg";
import "../styles/Alapr.css";

const Alapr4 = () => {
  return (
    <div className="alapr4">
            <img src={alapr4} alt="alapr4" />
          </div>
  );
};

export default Alapr4;
