import React from "react";
import './App.css';
import {Routes, Route, BrowserRouter as Router, useLocation} from 'react-router-dom';
import {useLayoutEffect} from 'react';
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Rolunk from "./pages/Home";
import Galeria from "./pages/Galeria";
import Alaprajzok from "./pages/Alaprajzok";
import Alapr1 from "./pages/Alapr1";
import Alapr2 from "./pages/Alapr2";
import Alapr3 from "./pages/Alapr3";
import Alapr4 from "./pages/Alapr4";
import Alapr5 from "./pages/Alapr5";
import Alapr6 from "./pages/Alapr6";
import Kapcsolat from "./pages/Kapcsolat";
import Muszaki from "./pages/Muszaki";
import Projektek from "./pages/Projektek";
//<Route path="/szolgaltatasok" element={<Szolgaltatasok />} />
//import Szolgaltatasok from "./pages/Szolgaltatasok";
import Haz1 from "./pages/Haz1";
import Haz2 from "./pages/Haz2";
import Haz3 from "./pages/Haz3";
import Haz4 from "./pages/Haz4";

const Wrapper = ({children}) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
} 

function App() {
  return (
  <div className="App">
    <Router>
    <Wrapper>
        <Navbar />
        <Routes>
          <Route path="/" element={<Rolunk />} />
          <Route path="/galeria" element={<Galeria />} />
          <Route path="/alaprajzok" element={<Alaprajzok />} />
          <Route path="/alapr1" element={<Alapr1 />} />
          <Route path="/alapr2" element={<Alapr2 />} />
          <Route path="/alapr3" element={<Alapr3 />} />
          <Route path="/alapr4" element={<Alapr4 />} />
          <Route path="/alapr5" element={<Alapr5 />} />
          <Route path="/alapr6" element={<Alapr6 />} />
          <Route path="/kapcsolat" element={<Kapcsolat />} />
          <Route path="/muszaki" element={<Muszaki />} />
          <Route path="/projektek" element={<Projektek />} />
          <Route path="/Haz1" element={<Haz1 />} />
          <Route path="/Haz2" element={<Haz2 />} />
          <Route path="/Haz3" element={<Haz3 />} />
          <Route path="/Haz4" element={<Haz4 />} />

        </Routes>
        <Footer />
        </Wrapper>
    </Router> 
     </div>
  );
}

export default App;
