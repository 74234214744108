import React, { useState, useEffect } from 'react';
import Logo from "../assets/logo.jpg";
import Navbar1 from "../assets/navbar1.JPG";
import { Link } from 'react-router-dom';
import { BsBricks } from 'react-icons/bs';
import '../styles/Navbar.css';

//          <Link to="/szolgaltatasok">Szolgáltatások</Link>
//              <Link to="/szolgaltatasok">Szolgáltatások</Link>

function Navbar() {   
  const [openMenu, setOpenMenu] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  useEffect(() => {
    const checkViewportWidth = () => {
      setIsMobileView(window.innerWidth <= 920);
    };

    window.addEventListener('resize', checkViewportWidth);

    checkViewportWidth();

    return () => {
      window.removeEventListener('resize', checkViewportWidth);
    };
  }, []);

  return (
    <div className={`navbar ${isMobileView ? 'mobile' : ''}`}>
    <div className="leftSide">

    <Link to="/" > 
    <img src={Logo} alt="Logo" />
    </Link>
     </div>  
      {!isMobileView && (
        <div className="rightSide">
          <Link to="/">Rólunk</Link>

          <Link to="/projektek">Projektek</Link>
          <Link to="/muszaki">Műszaki tartalom</Link>
          <Link to="/galeria">Galéria</Link>
          <Link to="/alaprajzok">Alaprajzok</Link>
          <Link to="/kapcsolat">Kapcsolat</Link>
        </div>
      )}
      {isMobileView && (
        <div className="rightSide">
          {openMenu && (
            <div className="menu">
              <Link to="/">Rólunk</Link>

              <Link to="/projektek">Projektek</Link>
              <Link to="/muszaki">Műszaki tartalom</Link>
              <Link to="/galeria">Galéria</Link>
              <Link to="/alaprajzok">Alaprajzok</Link>
              <Link to="/kapcsolat">Kapcsolat</Link>
            </div>
          )}
          <button
            className={`menuButton ${openMenu ? 'open' : ''}`}
            onClick={toggleMenu}
          >
            <BsBricks />
          </button>
        </div>
      )}
    </div>
  );
}

export default Navbar;
