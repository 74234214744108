import React, { useState } from "react";
import "../styles/Projektek.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import vadalma1 from "../assets/alapr2.jpg";
import vandor2 from "../assets/vandor2.jpg";
import janus1 from "../assets/janus1.jpg";
import vandor3 from "../assets/vandor3.jpg";

AOS.init();

 const images = [
  [
    {
      src: vandor2,
      alt: "Kökörcsin",
      description: "Ez a ház Kecskeméten eladó.",
      description2: "Bővebb információkért kérem kattintson a képre!",
      page: "https://ingatlan.com/33836277",
    },
  ],
  [
    {
      src: vadalma1,
      alt: "Magnólia",
      description: "Ez a ház Kecskeméten eladó.",
      description2: "Bővebb információkért kérem kattintson a képre!",
      page: "haz1",
    },
  ],

 // [
  //  {
   //  alt: "Ház3",
     // description: "Ez egy másik ház a városban.",
 //     description2: "Bővebb információkért kérem kattintson a képre!",
   //   page: "haz4",
  //  },
 // ],
 // [
  //  {
   //   src: janus1,
    //  alt: "Janus1",
  //    description: "Ez az épület a Janus Pannonius utcán található.",
  //    description2: "Bővebb információkért kérem kattintson a képre!",
  //    page: "haz3",
  //  },
 // ],
];

const Projektek = () => {
  const [modalImage, setModalImage] = useState(null);

  const redirectToPage = (page) => {
    // Ha a page kezdetén nincs 'http', akkor hozzáfűzzük a helyi URL-t
    const finalPage = page.startsWith('http') ? page : `http://localhost:3000/${page}`;
    window.location.href = finalPage;
  };

  return (
    <div className="projektek-container">
      <div data-aos="zoom-in"
      data-aos-delay="100"
      data-aos-offset="200"
    data-aos-duration="500"
    data-aos-easing="ease-in-out"
    data-aos-mirror="true"
    data-aos-once="false" >
      <h2>Projektek</h2>
      </div>
      {images.map((imageGroup, groupIndex) => (
        <div data-aos="flip-left"
        data-aos-delay="100"
      data-aos-offset="250"
      data-aos-duration="500"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="false" >
        <div key={groupIndex} className="keret2">
          {imageGroup.map((image, index) => (
            <div key={index} className="projekt-item">
            <div data-aos="fade-right"
            data-aos-delay="0"
      data-aos-offset="250"
      data-aos-duration="500"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="false" >
              <div className="iras">
                <h2>{image.alt}</h2>
                <p33>{image.description}</p33>
                <p34>{image.description2}</p34>
              </div>
              </div>
              <div data-aos="fade-left"
              data-aos-delay="0"
      data-aos-offset="215"
    data-aos-duration="500"
    data-aos-easing="ease-in-out"
    data-aos-mirror="true"
    data-aos-once="false" >
              <div className="jobbkep">
                <img
                  src={image.src}
                  alt={image.alt}
                  onClick={() => redirectToPage(image.page)}
                />
              </div>
              </div>
            </div>
          ))}
        </div>
        </div>
      ))}
    </div>
  );
};

export default Projektek;
