import React from 'react';
import Tam from "../assets/tamogatas.jpg";
import { AiFillFacebook } from 'react-icons/ai';
import { TbBuildingSkyscraper } from 'react-icons/tb';
import { GrMail } from 'react-icons/gr';
import { FiPhoneCall } from 'react-icons/fi';
import { HiOutlineOfficeBuilding } from 'react-icons/hi';
import "../styles/Footer.css";

// majd Magnólia 32

function Footer() {
    return (
        <div className="footer">
            <div className="socialMedia"><AiFillFacebook /></div>
            <p3><TbBuildingSkyscraper /> 6000 Kecskemét, Magnólia utca 30.</p3>
            <p3><HiOutlineOfficeBuilding /> 6000 Kecskemét, Kőrösi-hegy 36.</p3>
            <p4><FiPhoneCall /> +36205339466 </p4>
            <p5><GrMail /> info@redhomebau.hu </p5>
            <div className="tam">
                <img src={Tam} alt="Tamogatas" />
            </div>
            <p6></p6>
            <p7></p7> 
            <p2> &copy; 2023 Red-Home Bau Kft </p2>
        </div>
    );
}

export default Footer;
