import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../styles/Muszaki.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import avant from "../assets/avant1.jpg";
import alapozas from "../assets/alapozas2.jpg";
import vandor from "../assets/vandor32.jpg";
import janus from "../assets/janus111.jpg";
import teto from "../assets/teto.jpg";
import kemeny from "../assets/kemeny.jpg";
import badog from "../assets/badog.jpg";
import vasbeton from "../assets/vasbeton.jpg";
import vakolas from "../assets/vakolas.jpg";
import szigeteles from "../assets/szigeteles.jpg";
import aljzatbeton from "../assets/aljzatbeton.jpg";
import hidegburkolat from "../assets/hidegburkolat2.jpg";
import melegburkolat from "../assets/melegburkolat2.jpg";
import ablak from "../assets/ablak2.jpg";
import konyoklo from "../assets/konyoklo.jpg";
import bb from "../assets/bb.jpg";
import ajto from "../assets/ajto.jpg";
import cso from "../assets/cso2.jpg";
import ho from "../assets/ho.jpg";
import villany from "../assets/villany.jpg";

AOS.init();

function Szolgaltatasok() {
    return (
        <div className="keret2">
        <div data-aos="zoom-in"
        data-aos-delay="100"
        data-aos-offset="200"
      data-aos-duration="500"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="false" >
      <div>
        <h2>Műszaki tartalom</h2>
        </div>
        </div>
        <div data-aos="zoom-in-right"
      data-aos-offset="200"
      data-aos-duration="500"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="false" >
        <div className="keretbal">
        <div className="iras"><h2>FÖLDMUNKA</h2>
        <p22>Az öntött beton sávalapok gépi vagy kézi földkiemelése maximum 45 cm szélességben, kézzel tisztázva, a talajmechanikai jellemzők függvényében történik. A lábazati falak közé géppel tömörített töltésföld kerül, majd annak felső záró rétege tömörített kavicsréteg. </p22>  </div>
            <div class="balkep">
                <img src={avant}/>
            </div>
        </div>
        </div>
        <div data-aos="zoom-in-left"
      data-aos-offset="200"
      data-aos-duration="500"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="false" >
        <div className="keretbal">
        <div class="balkep">
                <img src={alapozas}/>
            </div>
        <div className="iras"><h2>ALAPOZÁS</h2>
        <p23>A külső és belső szerkezeti falak alatt az optimális esetben 0,9 méter mélységben elhelyezkedő teherbíró talajrétegre támaszkodó, 40 cm széles úsztatott sávalap készül. 
        A rendelkezésre álló tervdokumentációk alapján kiszámított a sávalapba betölthető beton mennyiségéhez képest esetleges + 20%-os többlet beton mennyiséget a kivitelező még átvállalja, de az a fölötti mennyiséget pótmunkaként felszámolja.
         Az alapok betonacéllal történő megerősítő vasalással készülnek.
        Ettől eltérő paraméterek esetében egyedi statikai tervek alapján, külön árajánlat készül az alapozásra.
        Ezen munkákat a kivitelező az előzetes egyeztetések alapján pótmunkaként végez el, melynek összegét a következő munkafázis megkezdése előtt bekéri.
        </p23> </div>
        </div>
        </div>
        <div data-aos="zoom-in-right"
      data-aos-offset="200"
      data-aos-duration="500"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="false" >
        <div className="keretbal">
        <div className="iras"><h2>TEHERHORDÓ ÉS VÁLASZFALAK</h2>
        <p22>A teherhordó és külső térelhatároló falak (11 sor magas) Porotherm X-Therm Okostégla kézi falazóelemből, és a belső teherhordó szerkezetek vasbeton pillérvázból készülnek. 
        Rejtett redőnytok kialakítása lehetséges, de azt a felek a tervezési, és árazási fázisban rögzítik.
        A válaszfalak 10 cm-es vastagságban téglából kerülnek kiépítésre.
        Az esetleges gipszkartonos válaszfal kialakítást a felek a tervezési, és árazási fázisban rögzítik.
        A lábazati fal 1 sor magas 30 cm széles LEIER v. FRÜHWALD beton falazó elemből készül.
        Az egyedi igények, az építéshatósági szabályozás, valamint a helyszíni szemle során a terepviszonyok figyelembe vételekor meghatározott lábazati sor emelési igényt a felek a tervezési, és árazási fázisban rögzítik. 
        </p22> </div>
            <div class="balkep">
                <img src={vandor}/>
            </div>
        </div>
        </div>
        <div data-aos="zoom-in-left"
      data-aos-offset="200"
      data-aos-duration="500"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="false" >
        <div className="keretbal">
        <div class="balkep">
                <img src={janus}/>
            </div>
            <div className="iras"><h2>FÖDÉMSZERKEZET</h2>
        <p23>Szeglemezes rácsos faszerkezet/ „hagyományos” fa födémszerkezet
        A szeglemezes rácsos faszerkezet egy előre gyártott rendszer, amely egyben tartalmazza a födém és a tetőszerkezetet is, melyet szárított faanyagból üzemi körülmények között kerül gyártásra. Ezáltal a száradási és vetemedési problémák kizárhatók. A födém gerendák között 30 cm vastag üveggyapot hőszigetelés kerül beépítésre. A garázs födémének gerendái közé 15 cm üveggyapot szigetelés kerül beépítésre. A födém faanyagát láng és gombamentesítő anyaggal kezeljük.        
        </p23> </div>
        </div>
        </div>
        <div data-aos="zoom-in-right"
      data-aos-offset="200"
      data-aos-duration="500"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="false" >
        <div className="keretbal">
        <div className="iras"><h2>TETŐSZERKEZET ÉS TETŐFEDÉS</h2>
        <p22>A családi lakóház tetőszerkezete fa szerkezet, melyet tetőfóliával, ellenléc-léc és kiszellőztető szerkezettel látunk el. A tetőszerkezet favédelmét, mártott technológiával végezzük. A tetőfedés a LEIER, TERRAN, vagy BRAMAC betoncserepekből valósul meg, melyek több alapszínből választhatóak, gerinc és kezdő elemekkel, tetőszivaccsal, hó szelővel, szellőzőcseréppel, bizonyos esetekben tetőkibúvóval, tetőjárdával.
        Lehetőség van egyéb betoncserepek alkalmazására is, melyekről előzetes árajánlatot készítünk, és a tervezési, és árazási fázisban rögzítünk.  </p22> </div>
            <div class="balkep">
                <img src={teto}/>
            </div>
        </div>
        </div>
        <div data-aos="zoom-in-left"
      data-aos-offset="100"
      data-aos-duration="500"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="false" >
        <div className="keretbal">
        <div class="balkep">
                <img src={kemeny}/>
            </div>
        <div className="iras"><h2>KÉMÉNY</h2>
        <p23>(előzetes egyeztetés és árazás alapján)
        Szilárd tüzelésre alkalmas hőszigetelt kémény megegyezés szerinti méretben, melyre kizárólag zárt égésterű tüzelő berendezést lehet rácsatlakoztatni. Az előre kiképzett frisslevegő utánpótlással, padlócsatornával. 
        </p23> </div>
        </div>
        </div>
        <div data-aos="zoom-in-right"
      data-aos-offset="200"
      data-aos-duration="500"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="false" >
        <div className="keretbal">
        <div className="iras"><h2>BÁDOGOS SZERKEZETEK</h2>
        <p22>Csapadékvíz elvezetés céljára hagyományos horganyzott csatornarendszer készül.
        Lehetőség van színezett ereszcsatorna alkalmazására is, melyről árajánlatot készítünk. A kiválasztott színes ereszcsatorna módosítás összegét előzetes egyeztetések alapján pótmunkaként tünteti fel, melynek összegét a következő munkafázis megkezdése előtt bekéri.
         </p22> </div>
            <div class="balkep">
                <img src={badog}/>
            </div>
        </div>
        </div>
        <div data-aos="zoom-in-left"
      data-aos-offset="200"
      data-aos-duration="500"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="false" >
        <div className="keretbal">
        <div class="balkep">
                <img src={vasbeton}/>
            </div>
        <div className="iras"><h2>HELYSZÍNI MONOLIT VASBETON</h2>
        <p23>Az egyéb vasbeton szerkezetek (kerámia „U” zsalut építünk be 23 cm magas), lemezek, pillérek, lépcsők és gerendák a statikai méretezés szerint készülnek. A lábazati falakra 6 / 15 / 15 hálós vasalású szerelőbeton készül, helyszíni, vagy transzportbetonból.
        </p23> </div>
        </div>
        </div>
        <div data-aos="zoom-in-right"
      data-aos-offset="200"
      data-aos-duration="500"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="false" >
        <div className="keretbal">
        <div className="iras"><h2>BELSŐ FALFELÜLETEK</h2>
        <p22>Az épület belső fal felületét gépi vakolással borítjuk. A vakolatot csiszolt felületű üvegszál erősítésű glettel látjuk el, amire választható színű diszperziós festés kerül.</p22> </div>
            <div class="balkep">
                <img src={vakolas}/>
            </div>
        </div>
        </div>
        <div data-aos="zoom-in-left"
      data-aos-offset="200"
      data-aos-duration="500"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="false" >
        <div className="keretbal">
        <div class="balkep">
                <img src={szigeteles}/>
            </div>
        <div className="iras"><h2>KÜLSŐ FALFELÜLETEK</h2>
        <p23>Az épület külső falaira 15 cm-es EPS80 polisztirollap hőszigetelést ragasztunk fel, ezzel a szerkezettel falaink hőszigetelő képessége megfelel az alacsony energiájú ház falazatának, melyre felkerül a színező nemes vakolat (első három színmélységig!). A lábazatot 10 cm-es XPS hőszigeteléssel tesszük hőhídmentessé, majd választható színű nemesvakolattal látjuk el. Az ereszek, teraszok, lépcsők lambéria borítása kültéri lazúr bevonattal készül választható színben. 
        Lehetőség van a polisztirollap hőszigetelés vastagságának növelésére a még kedvezőbb energetikai mutatók elérése érdekében, melyet a felek a tervezés, és árazás fázisában rögzítik.        
        </p23> </div>
        </div>
        </div>
        <div data-aos="zoom-in-right"
      data-aos-offset="200"
      data-aos-duration="500"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="false" >
        <div className="keretbal">
        <div className="iras"><h2>PADLÓK</h2>
        <p22>A szerelőbeton felületére kerül a lánggal összeolvasztott 1 réteg talajpára elleni 4 mm-es vízszigetelés, amire fektetjük a 10 cm vastag EPS 150 hőszigetelő lapokat, erre PE fólia kerül, majd a 6-8 cm vastagságú aljzatbeton. A terasz és lépcső padlószerkezete vasalt beton. </p22> </div>
            <div class="balkep">
                <img src={aljzatbeton}/>
            </div>
        </div>
        </div>
        <div data-aos="zoom-in-left"
      data-aos-offset="200"
      data-aos-duration="500"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="false" >
        <div className="keretbal">
        <div class="balkep">
                <img src={hidegburkolat}/>
            </div>
        <div className="iras"><h2>HIDEGBURKOLATOK</h2>
        <p23>A belső helyiségek hidegburkolatai a burkolási jegyzőkönyvben rögzített mennyiségben és árban, a Megrendelő által kiválasztott burkolólapokból ragasztva, hálóba rakva és fugázva készülnek, a következő helyiségekben konyha, háztartási helység, fürdőszoba, WC, kamra, előtér. A  konyhaszekrény köze 3 folyóméterig (maximum 2 m2 felületig) kerül burkolásra. A fürdőszoba és a WC fala 210 cm magasságig, igény esetén műanyag élvédővel kerül burkolásra. A konyha, kamra, előtér, háztartási helység a padlólap anyagából vágott szegővel készül. 
        A többi helyiség melegburkolattal készül. 
        Kültéri burkolás esetén fagyálló, kőporcelán burkolattal készülnek, flexibilis, fagyálló ragasztóba, flexibilis fugázóval fugázva.
        Speciális, a fent meghatározottaktól eltérő technikai megoldásoknál egyedi árakat ajánlunk ki.
        </p23> </div>
        </div>
        </div>
        <div data-aos="zoom-in-right"
      data-aos-offset="200"
      data-aos-duration="500"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="false" >
        <div className="keretbal">
        <div className="iras"><h2>MELEGBURKOLATOK</h2>
        <p22>Ajánlatunk laminált parketta melegburkolatot tartalmaz a burkolási jegyzőkönyvben rögzített mennyiségben és árban.
        Szegőléccel és habalátétes kopogáscsökkentő alátétlemezzel a kívánt színben vagy mintában. 
        Ettől eltérő melegburkolati igényeket is megvalósítunk, mint pl. hajópadló, keményfa vagy intarziás parketta, parafa burkolat, stb. az építtetővel történő egyeztetés után.
        Ezen munkákat a kivitelező az előzetes egyeztetések alapján pótmunkaként végez el, melynek összegét a következő munkafázis megkezdése előtt bekéri.
        Ajánlati árunk tartalmazza a minőségi burkolási munkához szükséges műanyag élvédőket és szilikon tömítőket.
        Melegburkolat hidegburkolatra való átváltása esetén technológiaváltási díjat számolunk fel négyzetméterenként! 
        </p22> </div>
            <div class="balkep">
                <img src={melegburkolat}/>
            </div>
        </div>
        </div>
        <div data-aos="zoom-in-left"
      data-aos-offset="200"
      data-aos-duration="500"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="false" >
        <div className="keretbal">
        <div class="balkep">
                <img src={ablak}/>
            </div>
        <div className="iras"><h2>NYÍLÁSZÁRÓ SZERKEZETEK</h2>
        <p23>A külső nyílászárók fokozott hőszigetelt 6 légkamrás 3 rétegű üvegezésű műanyag nyílászárók kívül-belül fehér színben.
        1 db bejárati ajtó kívül-belül fehér színben (5 pontos biztonsági műanyag ajtó, kilincscsel és zárszerkezettel)
        1 darab egy vagy kétszárnyas nyíló, kívül-belül fehér színű műanyag teraszajtó (kilinccsel, zárszerkezettel)
        Amennyiben megrendelői beszerzéssel érkeznek a kültéri nyílászárók, abban az esetben gondoskodnia kell azok beszereléséről is, a gyártói garancia miatt.
        </p23> </div>
        </div>
        </div>
        <div data-aos="zoom-in-right"
      data-aos-offset="200"
      data-aos-duration="500"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="false" >
        <div className="keretbal">
        <div className="iras"><h2>KÖNYÖKLŐK</h2>
        <p22>Az árajánlat fehér műanyag könyöklőket tartalmaz kívülre és belülre.
        A megrendelő egyedi igényei alapján a gyártótól visszaigazolt árak közötti különbséget a kivitelező az előzetes egyeztetések alapján pótmunkaként számol fel, melynek összegét a következő munkafázis megkezdése előtt bekéri.
         </p22> </div>
            <div class="balkep">
                <img src={konyoklo}/>
            </div>
        </div>
        </div>
        <div data-aos="zoom-in-left"
      data-aos-offset="200"
      data-aos-duration="500"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="false" >
        <div className="keretbal">
        <div class="balkep">
                <img src={bb}/>
            </div>
        <div className="iras"><h2>A HÁZ VÁRHATÓ ENERGETIKAI BESOROLÁSA</h2>
        <p23>A megrendelő által választott fűtési rendszertől, és egyéb választott energetikai paraméterektől, a fal-üvegfelület arányától, a ház tájolásától is függhet.
        A teljes épületre vonatkozó előre látható energetikai besorolás: BB
        </p23> </div>
        </div>
        </div>
        <div data-aos="zoom-in-right"
      data-aos-offset="200"
      data-aos-duration="500"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="false" >
        <div className="keretbal">
        <div className="iras"><h2>BELTÉRI NYÍLÁSZÁRÓK</h2>
        <p22>A beltéri ajtók utólag szerelhető tokosak, választhatóak kilinccsel együtt (küszöb és küszöbváltó nélkül). Ettől eltérő, akár különleges nyílászáró igényeket is előre egyeztetve, szívesen teljesítünk.
        A alap műszaki tartalomban rögzített nyílászáró beszerzési árak, és a megrendelő egyedi igényei alapján a gyártótól visszaigazolt árak közötti különbséget a kivitelező az előzetes egyeztetések alapján pótmunkaként számol fel, melynek összegét a következő munkafázis megkezdése előtt bekéri.
        </p22> </div>
            <div class="balkep">
                <img src={ajto}/>
            </div>
        </div>
        </div>
        <div data-aos="zoom-in-left"
      data-aos-offset="200"
      data-aos-duration="500"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="false" >
        <div className="keretbal">
        <div class="balkep">
                <img src={cso}/>
            </div>
        <div className="iras"><h2>KÖZMŰVEK</h2>
        <p23>A közművek telekhatárig történő behozatala és a mérőórák kiépítése az ügyfél feladata és költsége. A víz, szennyvíz, gáz és villany bekötését a mérőóráktól a családi házig, ajánlatunk maximum 20 folyóméterig tartalmazza. 
        A 20 folyómétert meghaladó közművezetés pótmunkaként kerül elszámolásra közművenként (a talajviszonyok függvényében) melynek összegét a kivitelező a következő munkafázis megkezdése előtt bekér.
        </p23> </div>
        </div>
        </div>
        <div data-aos="zoom-in-right"
      data-aos-offset="200"
      data-aos-duration="500"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="false" >
        <div className="keretbal">
        <div className="iras"><h2>ÉPÜLETGÉPÉSZET VÁLASZTHATÓ</h2>
        <p22>Gázfűtés: A családi lakóház fűtése és használati meleg víz ellátása korszerű, kondenzációs gáztüzelésű, 24 KW-os Ariston, vagy BOSCH kazánnal és padlófűtéssel készül. Padlófűtés osztó-gyűjtő 1-8 körig szekrénnyel, szereléssel, gázkazán bekötéssel. A fűtés manuális, hordozható termosztáttal szabályozható.
        Levegő-víz hőszivattyú: A családi lakóház fűtése a Bosch által gyártott prémium kategóriás levegő-víz hőszivattyú rendszerrel történik. A vízvezetékrendszer unipress ötrétegű csővezetékekkel, a szennyvíz-csatornázás KG-PVC lefolyócsővel szerelten kerül kiépítésre.
        </p22> </div>
            <div class="balkep">
                <img src={ho}/>
            </div>
        </div>
        </div>
        <div data-aos="zoom-in-left"
      data-aos-offset="200"
      data-aos-duration="500"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="false" >
        <div className="keretbal">
        <div class="balkep">
                <img src={villany}/>
            </div>
        <div className="iras"><h2>VILLANYSZERELÉS</h2>
        <p23>A családi lakóház elektromos rendszere 1 db belső süllyesztett elosztószekrénnyel, rézvezetékekkel, kábelekkel és szerelvényekkel, lámpatestek nélkül készül.
        A szerelvények mennyiségét a villanyszerelési melléklet tartalmazza. (kb. 60db/100 m2-es ház)
        Villanyszerelési többletmunkák, vagy plusz berendezések (LED szalag, plusz konnektorok, kapcsolók, udvari világítások) beépítése esetén a kivitelező a módosítások összegét előzetes egyeztetések alapján pótmunkaként tünteti fel, melynek összegét a következő munkafázis megkezdése előtt bekéri.
        </p23> </div>
        </div>
        </div>
        </div>
    )
}

export default Szolgaltatasok;