import React from "react";
import alapr2 from "../assets/alapr2.jpg";
import "../styles/Alapr.css";

const Alapr2 = () => {
  return (
    <div className="alapr2">
            <img src={alapr2} alt="alapr2" />
          </div>
  );
};

export default Alapr2;
