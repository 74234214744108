import React from "react";
import "../styles/LightboxModal.css";
import { IoIosArrowBack } from 'react-icons/io';
import { IoIosArrowForward } from 'react-icons/io';
import { IoMdClose } from 'react-icons/io';

function LightboxModal({ imageUrlList, index, onClose }) {
  const handleNext = () => {
    // Calculate the previous index while considering the circular nature of the images
    const previousIndex = (index - 1 + imageUrlList.length) % imageUrlList.length;
    onClose(previousIndex);
  };

  const handleModalClick = (event) => {
    // Close the modal when clicking on the background or close icon
    if (
      event.target.classList.contains("lightbox-modal") ||
      event.target.classList.contains("close")
    ) {
      onClose();
    }
  };

  return (
    <div className="lightbox-modal" onClick={handleModalClick}>
      <div className="lightbox-content">
        <IoIosArrowBack className="arrowback" onClick={handleNext} />
        <img src={imageUrlList[index]} alt="Large Image" />
        <IoIosArrowForward className="arrownext" onClick={handleNext} />
        <IoMdClose className="close" onClick={onClose} />
      </div>
    </div>
  );
}

export default LightboxModal;
