import React, { useState } from "react";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "../styles/Haz.css";
import ClickableImage from "./ClickableImage";
import janus1 from "../assets/janus1.jpg";
import janus11 from "../assets/janus11.jpg";
import janus111 from "../assets/janus111.jpg";
import janus1111 from "../assets/janus1111.jpg";


const slides = [
  { src: janus1, alt: "Janus Ház", className: "img" },
  { src: janus11, alt: "Janus Ház", className: "img" },
  { src: janus111, alt: "Janus Ház", className: "img" },
  { src: janus1111, alt: "Janus Ház", className: "img" }
];

const Haz3 = () => {
  const [index, setIndex] = useState(-1);

  return (
   <div className="keret">
   <h2>Janus Pannonius</h2>
   <p33>Ez a ház a Janus Pannonius utcában található.</p33>
    <>
      <div className="img">
        {slides.map((slide, index) => (
          <ClickableImage
            key={index}
            src={slide.src}
            alt={slide.alt}
            onClick={() => setIndex(index)}
            className="custom-lightbox"
          />
        ))}
      </div>

      <PhotoAlbum
        className="img"
        photos={slides}
        targetRowHeight={150}
        onClick={({ index: current }) => setIndex(current)}
      />

      <Lightbox
        index={index}
        slides={slides}
        open={index >= 0}
        close={() => setIndex(-1)}
        className="custom-lightbox"
        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
      />
    </>
    </div>
  );
};

export default Haz3;
