import React from "react";
import { Link } from "react-router-dom";
import "../styles/Home.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import akcio1 from "../assets/hortenzia1720.png";
import akcio2 from "../assets/telep720.jpg";
import akcio3 from "../assets/magnolia4720.jpg";
import akcio4 from "../assets/akcio2720.jpg";
import vandorfecske2 from "../assets/vandor2720.jpg";
import vandorfecske1 from "../assets/vandor333720.jpg";
import alapr3480 from "../assets/alapr3480.jpg";
import alapr6480 from "../assets/alapr6480.jpg";
import kapcsolat from "../assets/telep.jpg";

AOS.init();

function Rolunk() {
  return (
    <body>
      <div class="slider">
        <div class="slide-track">
          {[akcio1, akcio2, akcio3, akcio4, akcio1, akcio2, akcio3, akcio4, akcio1, akcio2, akcio3, akcio4, akcio1, akcio2, akcio3, akcio4, akcio1, akcio2, akcio3, akcio4, akcio1, akcio2, akcio3, akcio4, akcio1, akcio2, akcio3, akcio4, ].map((image, index) => (
            <div class="slide" key={index}>
              <Link to="/galeria">
                <div className="image-container">
                  <img src={image} alt={`Slide ${index + 1}`} />
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div data-aos="fade-right"
      data-aos-offset="40"
      data-aos-duration="500"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="false" >
      <div className="keret">
        <h2>Jelenleg futó projektjeink</h2>
        <p33 className="szo">Jelenleg több projektünk is fut párhuzamosan. Bővebb információkért kérem kattintson a képre!</p33>
        <div className="projektkep">
          <Link to="/projektek">
              <img src={vandorfecske2} alt="vandor2" />
              <img src={vandorfecske1} alt="vandor1" />
          </Link>
          </div>
          <div className="projektkep3">
          <Link to="/projektek">
          <img src={vandorfecske1} alt="vandor1" />
          </Link>
        </div>
      </div>
      </div>
      <div data-aos="fade-left"
      data-aos-offset="50"
    data-aos-duration="500"
    data-aos-easing="ease-in-out"
    data-aos-mirror="true"
    data-aos-once="false" >
      <div className="keret">
      <h2>Alaprajzaink</h2>
        <p33 className="szo">Cégünk korábbi épület alaprajzai is megtekinthetőek az oldalon a képekre kattintva.</p33>
        <div className="projektkep">
          <Link to="/alaprajzok">
              <img src={alapr3480} alt="rejt" />
              <img src={alapr6480} alt="Alapr6" />
          </Link>
        </div>
        <div className="projektkep3">
        <Link to="/alaprajzok">
            <img src={alapr6480} alt="Alapr6" />
        </Link>
      </div>
      </div>
      </div>
      <div data-aos="zoom-in"
      data-aos-offset="50"
    data-aos-duration="500"
    data-aos-easing="ease-in-out"
    data-aos-mirror="true"
    data-aos-once="false" >
      <div className="keret">
      <h2>Elérhetőségeink</h2>
        <p33 className="szo">Ha bármi kérdése lenne kattintson a képre és írjon nekünk e-mail-t, vagy keressen az elérhetőségeinken!</p33>
        <div className="projektkep2">
          <Link to="/kapcsolat">
              <img src={kapcsolat} alt="Alapr3" />
          </Link>
        </div>
      </div>     
      </div>
    </body>
  );
}

export default Rolunk;
