import React from "react";
import alapr1 from "../assets/alapr1.jpg";
import "../styles/Alapr.css";

const Alapr1 = () => {
  return (
    <div className="alapr1">
            <img src={alapr1} alt="alapr1" />
          </div>
  );
};


export default Alapr1;
